import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { themeGet } from 'styled-system';

import Sitewidth from '../components/Sitewidth';
import Layout from '../components/Layout';
import Block from '../components/Block';
import TextBlock from '../components/TextBlock';
import Contact from '../components/Footer/Contact';
import BrandCard from '../components/Cards/BrandCard';
import CityCard from '../components/Cards/CityCard';
import Swiping from '../components/Slider/Swiping';
import AnimationHorizontal from '../components/Animate';
import VideoModal,
{ VideoModalButtonWithContext }
  from '../components/VideoModal';

import connect from '../assets/videos/connect.mp4';
import create from '../assets/videos/create.mp4';
import innovate from '../assets/videos/innovate.mp4';
import photo1 from '../assets/images/belgrade.jpg';
import photo2 from '../assets/images/toronto.jpg';
import photo3 from '../assets/images/dubai.jpg';
import photo4 from '../../static/img/office.jpg';
import photo5 from '../assets/images/1.jpg';
import photo6 from '../assets/images/2.jpg';
import photo7 from '../assets/images/3.jpg';

const OUR_CULTURE = [
  {
    src: photo5,
    type: 'static',
    title: 'Our culture',
    label: 'We are a colony of innovators. Experts in user experience, design, development and technology.',
    description: 'We combine creative talent with a comprehensive technological approach to overcome any challenge. We are perfectionists that are independent and entirely in-house.<br /><br /> We are a colony of innovators. Experts in user experience, design, development and technology.',
  },
  {
    src: photo6,
    type: 'static',
    title: 'Our culture',
    label: 'We are a colony of innovators. Experts in user experience, design, development and technology.',
    description: 'We combine creative talent with a comprehensive technological approach to overcome any challenge. We are perfectionists that are independent and entirely in-house.<br /><br /> We are a colony of innovators. Experts in user experience, design, development and technology.',
  },
  {
    src: photo7,
    type: 'static',
    title: 'Our culture',
    label: 'We are a colony of innovators. Experts in user experience, design, development and technology.',
    description: 'We combine creative talent with a comprehensive technological approach to overcome any challenge. We are perfectionists that are independent and entirely in-house.<br /><br /> We are a colony of innovators. Experts in user experience, design, development and technology.',
  },
];

const CITIES = [
  {
    label: 'United Arab Emirates',
    title: 'Dubai',
    src: photo3,
    to: 'mailto:careers@dxbee.io',
    utcOffset: 240,
  },
  {
    label: 'Serbia',
    title: 'Belgrade',
    src: photo1,
    to: 'https://thehive.rs/careers',
    utcOffset: 120,
  },
  {
    label: 'Canada',
    title: 'Toronto',
    src: photo2,
    to: 'mailto:careers@dxbee.io',
    utcOffset: -240,
  },
];

const CONTENT = [
  {
    label: '',
    maxWidth: '600px',
    color: 'default',
    title: 'Create',
    quote: `Our Journey begins by creating digital products for and with
    partners. Our designers, technologists, and strategists work side by side
    with partners to rapidly conceptualize, build and launch a new venture
    similar to a bee creating honey, that is unique and sticks to your
    audience and business goals.`,
    src: create,
  },
  {
    label: '',
    maxWidth: '600px',
    color: 'default',
    title: 'Connect',
    quote: `Without the ability to get a product to market and into
    users’ hands, even the greatest idea, funding,
    and network are nigh-on meaningless. This is the thing that
    companies with almost infinite resources greatly covet.
    The same way the bee swarms from pollen to pollen assessing,
    connecting and targeting the optimal pathway to meet their
    objective. We serve as the missing piece, the essential
    component, to effectively use our expertise to launch
    and market any digital product.`,
    src: connect,
  },
  {
    label: '',
    maxWidth: '600px',
    color: 'default',
    title: 'Innovate',
    quote: `We incubate ideas, startups and products until they are
    ready to fly the hive. Initially we seek to establish a parallel
    and independent team until we decide push the product out of the
    hive as an independent but federated company with its own
    leadership. With a dedicated venture team with investment
    experience that oversees the portfolio and that seeks out and
    validates new opportunities for the company. We leverage our
    product expertise and market insight to scale and keep on
    innovating at every touch point.`,
    src: innovate,
  },
];

const Overflow = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

// const OverflowVideo = styled.div`
//   width: 100%;
//   height: 100%;
//   border-radius: 10px;

//   @media(min-width: ${themeGet('breakpoints.2')}) {
//     padding-top: 15%;
//   }
// `;

const VideoWrapper = styled(Block)``;

const Video = styled.video`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

const BrandCardWrapper = styled.div`
  position: absolute;
  right: 2%;
  top: -8%;
  width: 40%;

  @media(max-width: ${themeGet('breakpoints.2')}) {
    display: none;
  }
`;

const Content = styled(Block)`
  flex-direction: column;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: ${themeGet('space.4')} 0;

  ${Video} {
    max-width: 400px;
  }

  div:nth-child(even) > & {
    flex-direction: row-reverse;
  }

  @media(max-width: ${themeGet('breakpoints.1')}) {
    flex-direction: column !important;

    ${Video}:first-child {
      order: 2;
    }
  }

  @media(max-width: ${themeGet('breakpoints.2')}) {
    ${Video} {
      max-width: 300px;
    }
  }
`;

const CitiesWrapper = styled(Block)`
  background: ${themeGet('colors.secondary.base')};
`;

const Cities = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  & > :not(:last-child) {
    margin-bottom: 20px;
  }

  @media(min-width: ${themeGet('breakpoints.1')}) {
    flex-direction: row;
    width: auto;
    
    & > :not(:last-child) {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
`;

const SliderWrapper = styled(Block)``;

const ImageWrapper = styled(Block)`
  overflow: hidden;
  border-radius: 10px;
  height: 200px;

  @media(min-width: ${themeGet('breakpoints.1')}) {
    height: 500px;
  }

  @media(min-width: ${themeGet('breakpoints.2')}) {
    height: 700px;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 10px;
`;

const Normalize = styled.div`
  width: 100%;
`;

const About = () => (
  <Layout>
    <VideoModal>
      <Overflow>
        <Sitewidth py={[4, 5, 6]}>
          <TextBlock
            label=""
            title="About us"
            description="We partner with inspiring entrepreneurs and
            values-driven companies to design and launch world-class digital
            products, systems and experiences."
            as="h1"
            size="default"
          />
          {/* <OverflowVideo> */}
          <VideoWrapper py={[4, 5]} mt={[2, 3, 7]}>
            <ImageWrapper>
              <AnimationHorizontal direction="btt" delay={100}>
                <Image src={photo4} alt="" />
              </AnimationHorizontal>
            </ImageWrapper>
            <BrandCardWrapper>
              <AnimationHorizontal delay={200}>
                <BrandCard
                  label="We're the"
                  title="Digital xperience Bee"
                />
              </AnimationHorizontal>
            </BrandCardWrapper>
            <VideoModalButtonWithContext
              video={{
                video: parseInt(150110400, 10),
                muted: false,
              }}
              brandColor="hsl(42,100%,55%)"
            />
          </VideoWrapper>
          {/* </OverflowVideo> */}
          <Content py={[4, 5]}>
            {CONTENT.map((item, index) => (
              <AnimationHorizontal
                key={item.title}
                direction="btt"
                delay={index * 100}
              >
                <Row>
                  <TextBlock
                    label={item.label}
                    maxWidth={item.maxWidth}
                    color={item.color}
                    title={item.title}
                    quote={item.quote}
                  />
                  <Video autoPlay loop muted src={item.src} />
                </Row>
              </AnimationHorizontal>
            ))}
          </Content>
        </Sitewidth>
        <ThemeProvider theme={{ mode: 'dark' }}>
          <CitiesWrapper py={[5, 6, 7]}>
            <Sitewidth
              justifyContent={['center', null, null, 'flex-start']}
              alignItems={['center', null, null, 'flex-start']}
            >
              <TextBlock
                label=""
                title="30+ Digital Experts across the globe"
                quote="Find us near you"
                color="default"
              />
              <Block py={[3, 4]} />
              <Cities>
                {CITIES.map((item, index) => (
                  <AnimationHorizontal
                    key={item.title}
                    direction="btt"
                    delay={index * 100}
                  >
                    <Normalize>
                      <CityCard
                        label={item.label}
                        title={item.title}
                        src={item.src}
                        to={item.to}
                        utcOffset={item.utcOffset}
                        type={item.type}
                        as="h3"
                      />
                    </Normalize>
                  </AnimationHorizontal>
                ))}
              </Cities>
            </Sitewidth>
          </CitiesWrapper>
        </ThemeProvider>
        <Sitewidth>
          <AnimationHorizontal direction="btt">
            <SliderWrapper py={[5, 6, 7]}>
              <Swiping
                showButton
                order="title"
                files={OUR_CULTURE}
                dataText="We're hiring"
                to="/contact"
                marginBottom={false}
              />
            </SliderWrapper>
          </AnimationHorizontal>
        </Sitewidth>
        <Contact
          label="Everything digital!"
          title="Our services"
          quote="Revolutionizing your approach is vital. It is virtually
          impossible to outshine your competition without introducing innovative
          tactics; both online and offline. The new consumer expects
          personalized, interactive products & services."
          color="default"
          dataText="Learn more"
          to="/services"
        />
      </Overflow>
    </VideoModal>
  </Layout>
);

export default About;
