/**
 * BrandCard
 * ---------
 * extends: Block
 * props:
 *  - label: [string] **required**
 *  - title: [string] **required**
 */

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { themeGet } from 'styled-system';
import { timingFunctions } from 'polished';
import PropTypes from 'prop-types';

import Block from '../../Block';
import { Label, Title } from '../../Text';

const Wrapper = styled(Block)`
  background: ${themeGet('colors.secondary.base')};
  max-width: 550px;
  border-radius: 10px;
  box-shadow: 1px 3px 20px ${themeGet('colors.black.shade.3')};

  ${Title}:before {
    content: attr(data-start);
    color: ${themeGet('colors.primary.base')};
  }
`;

const Equalizer = styled(Block)`
  overflow: hidden;
  height: 50px;
`;

const Bar = styled.div`
  position: absolute;
  left: ${props => props.index * 10}px;

  height: 100%;
  transform: translateY(-100%);
  width: 2px;
  background: ${themeGet('colors.white.shade.0')};

  animation:
    equalizer
      2000ms
      ${timingFunctions('easeInOutQuad')}
      ${props => (props.index) * 160}ms
      infinite;

  @keyframes equalizer {
    50%, 100% {
      transform: translateY(100%);
    }
  }
`;

const BrandCard = ({ label, title }) => {
  const splitTitle = title.split(' ');
  return (
    <ThemeProvider theme={{ mode: 'dark' }}>
      <Wrapper p={[5, 6]}>
        <Label>{label}</Label>
        <Title data-start="D">{splitTitle[0].slice(1)}</Title>
        <Title data-start="X">{splitTitle[1].slice(1)}</Title>
        <Title data-start="Bee" />
        <Equalizer mt={[3, 4]}>
          {[1, 2, 3, 4, 5].map((_item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Bar key={index} index={index} />
          ))}
        </Equalizer>
      </Wrapper>
    </ThemeProvider>
  );
};

BrandCard.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default BrandCard;
